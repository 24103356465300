<template>
  <div class="dashboard-container">
    <b-container fluid>
      <!-- Header Section -->
      <b-card
        no-body
        class="shadow-sm border-0 mb-4"
      >
        <b-card-body>
          <div>
            <h1 class="display-6 mb-0">
              <span class="text-primary">B2C</span>
              <span class="badge bg-primary-subtle">🚀</span>
            </h1>
            <p>Statistique de livraison {{ getDateRangeText }}</p>
          </div>
          <div v-if="isLoading">
            <b-spinner
              variant="primary"
              label="Loading..."
            />
          </div>
        </b-card-body>
      </b-card>

      <!-- Filters Section -->
      <b-card
        no-body
        class="shadow-sm border-0 mb-4"
      >
        <b-card-body>
          <b-row align-v="center">
            <b-col md="6">
              <b-form-group
                label="Date de début"
                label-class="text-muted font-weight-bold"
              >
                <b-form-datepicker
                  v-model="fromDate"
                  :max="toDate || undefined"
                  locale="fr"
                  placeholder="Sélectionner une date"
                  class="date-picker-custom"
                  today-button
                  reset-button
                  close-button
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Date de fin"
                label-class="text-muted font-weight-bold"
              >
                <b-form-datepicker
                  v-model="toDate"
                  :min="fromDate || undefined"
                  locale="fr"
                  placeholder="Sélectionner une date"
                  class="date-picker-custom"
                  today-button
                  reset-button
                  close-button
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col class="d-flex justify-content-end">
              <b-button
                variant="primary"
                class="mr-2"
                @click="applyFilters"
              >
                <i class="fas fa-filter mr-2" />
                Appliquer les filtres
              </b-button>
              <b-button
                variant="outline-secondary"
                @click="resetFilters"
              >
                <i class="fas fa-undo mr-2" />
                Réinitialiser
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <!-- Repositories and Drivers Section -->
      <div
        v-for="repository in topDrivers"
        :key="repository.repository_id"
        class="mb-4"
      >
        <b-card
          no-body
          class="shadow-sm border-0"
        >
          <b-card-header class="bg-white border-bottom-0">
            <h3>{{ repository.repository_name }}</h3>
          </b-card-header>
          <b-card-body class="pt-0">
            <b-row>
              <b-col
                v-for="(driver, driverIndex) in repository.top_drivers"
                :key="driver.driver_id"
                lg="6"
                class="mb-4"
              >
                <b-card
                  no-body
                  class="h-100 driver-card border-0 shadow-sm"
                  :class="{'top-performer': driverIndex < 3}"
                >
                  <b-card-body>
                    <!-- Driver Header -->
                    <div class="d-flex justify-content-between align-items-start mb-3">
                      <div>
                        <h4>{{ driver.driver_name }}</h4>
                        <div class="stats">
                          <div class="d-flex flex-column">
                            <!-- Delivery stats -->
                            <div class="mb-2">
                              <b-badge
                                variant="success"
                                class="mr-2"
                              >
                                {{ driver.delivered_orders_count }} livraisons
                              </b-badge>
                              <b-badge
                                variant="success"
                                class="mr-2"
                              >
                                {{ formatPrice(driver.total_delivery_price) }} ( Frais livraison)
                              </b-badge>
                            </div>
                            <!-- Return stats -->
                            <div>
                              <b-badge
                                variant="danger"
                                class="mr-2"
                              >
                                {{ driver.return_orders_count }} retours
                              </b-badge>
                              <b-badge
                                variant="danger"
                                class="mr-2"
                              >
                                {{ formatPrice(driver.total_return_price) }} ( Frais retour)
                              </b-badge>
                            </div>
                          </div>
                        </div>
                      </div>
                      <b-badge
                        pill
                        :variant="driverIndex < 3 ? 'warning' : 'primary'"
                        class="rank-badge"
                      >
                        #{{ driverIndex + 1 }}
                      </b-badge>
                    </div>

                    <!-- Performance Metrics -->
                    <div class="metrics-grid">
                      <div class="metric-card">
                        <div class="metric-title">
                          Taux de retour
                        </div>
                        <div class="metric-value">
                          {{ calculateReturnRate(driver) }}%
                        </div>
                      </div>
                    </div>

                    <!-- Daily Performance -->
                    <div class="daily-performance mt-4">
                      <h6>Détails quotidiens</h6>
                      <b-list-group flush>
                        <b-list-group-item
                          v-for="detail in driver.daily_stats"
                          :key="detail.date"
                          class="d-flex justify-content-between align-items-center px-0"
                        >
                          <span>{{ formatDate(detail.date) }}</span>
                          <div class="daily-stats">
                            <b-badge
                              variant="success"
                              class="mr-2"
                            >
                              {{ detail.delivered_count }} livraisons
                            </b-badge>
                            <b-badge
                              variant="success"
                              class="mr-2"
                            >
                              {{ formatPrice(detail.delivery_price) }}
                            </b-badge>
                          </div>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </div>
    </b-container>
  </div>
</template>

<script>
import {
  BCard,
  BSpinner,
  BFormDatepicker,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BContainer,
  BCardBody,
  BCardHeader,
  BBadge,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import axios from 'axios'
import storeAuth from '@/store/store'

export default {
  name: 'Dashboard',

  components: {
    BCard,
    BSpinner,
    BFormDatepicker,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BContainer,
    BCardBody,
    BCardHeader,
    BBadge,
    BListGroup,
    BListGroupItem,
  },

  data() {
    return {
      isLoading: false,
      user: {},
      topDrivers: [],
      fromDate: null,
      toDate: null,
    }
  },

  computed: {
    getDateRangeText() {
      if (this.fromDate && this.toDate) {
        return `pour la période du ${this.formatDate(this.fromDate)} au ${this.formatDate(this.toDate)}`
      }
      return 'pour le mois en cours'
    },
  },

  created() {
    this.user = storeAuth.state.user
    if (this.user.role === 'admin') {
      this.fetchTopDrivers()
    }
  },

  methods: {
    calculateReturnRate(driver) {
      const total = driver.delivered_orders_count + driver.return_orders_count
      if (total === 0) return '0.00'
      return ((driver.return_orders_count / total) * 100).toFixed(2)
    },

    calculateAverageReturnPrice(driver) {
      if (driver.return_orders_count === 0) return this.formatPrice(0)
      const average = driver.total_return_price / driver.return_orders_count
      return this.formatPrice(average)
    },

    calculateAverageDeliveryPrice(driver) {
      if (driver.delivered_orders_count === 0) return this.formatPrice(0)
      const average = driver.total_delivery_price / driver.delivered_orders_count
      return this.formatPrice(average)
    },

    formatPrice(price) {
      return new Intl.NumberFormat('fr-TN', {
        style: 'currency',
        currency: 'TND',
      }).format(price || 0)
    },

    async fetchTopDrivers() {
      try {
        this.isLoading = true
        const params = new URLSearchParams()
        if (this.fromDate) params.append('from_date', this.fromDate)
        if (this.toDate) params.append('to_date', this.toDate)

        // Fix URL construction
        const baseUrl = '/api/kpi/top-drivers'
        const url = params.toString() ? `${baseUrl}?${params.toString()}` : baseUrl
        const { data } = await axios.get(url)
        this.topDrivers = data
      } catch (error) {
        console.error('Error fetching top drivers:', error)
        // Enhanced error logging
        if (error.response) {
          console.error('Error response:', error.response.data)
          console.error('Status:', error.response.status)
        }
        this.$bvToast.toast('Erreur lors du chargement des données', {
          title: 'Erreur',
          variant: 'danger',
          solid: true,
        })
      } finally {
        this.isLoading = false
      }
    },

    applyFilters() {
      this.fetchTopDrivers()
    },

    resetFilters() {
      this.fromDate = null
      this.toDate = null
      this.fetchTopDrivers()
    },

    formatDate(dateString) {
      if (!dateString) return ''
      return new Intl.DateTimeFormat('fr-FR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).format(new Date(dateString))
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard-container {
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 1.5rem;
}

.date-picker-custom {
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;

  &:focus-within {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

.driver-card {
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }

  &.top-performer {
    border-left: 4px solid #ffc107 !important;
  }
}

.rank-badge {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.stats {
  .badge {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  margin-top: 1.5rem;
}

.metric-card {
  background-color: #f8f9fa;
  border-radius: 0.5rem;
  padding: 1rem;
  text-align: center;

  .metric-title {
    font-size: 0.875rem;
    color: #6c757d;
    margin-bottom: 0.5rem;
  }

  .metric-value {
    font-size: 1.25rem;
    font-weight: 600;
    color: #212529;
  }
}

.daily-performance {
  .list-group-item {
    border: none;
    padding: 0.75rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid #f0f0f0;
    }
  }
}

.daily-stats {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .badge {
    font-weight: 500;
  }
}

.badge {
  &.badge-danger {
    background-color: #dc3545;
    color: white;
  }

  &.badge-success {
    background-color: #28a745;
    color: white;
  }
}
</style>
